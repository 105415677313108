/**
 *
 * GRAPHICS
 *
 */

.graphic {
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  display: block;
  width: 100%;
}

.graphic__picture {
  position: relative;
  display: block;
  background-color: color('neutrals-3');
}

.graphic__image {
  transition: transform 700ms;
  display: block;
  width: 100%;

  a[href]:hover & {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.graphic__copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: gap('xxxsmall');
  color: color('neutrals-0');
  font-size: fontSize('xxsmall');
  background-color: rgba(color('neutrals-8'), .7);
  border-top-right-radius: 3px;

  &:empty {
    display: none;
  }
}
