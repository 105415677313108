/**
 *
 * LIST
 *
 */

.list {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  list-style-type: none;
}

.list__item {
  display: flex;
  align-items: baseline;
  margin-top: gap('xxsmall');
  color: inherit;

  break-inside: avoid;

  &:first-child {
    margin-top: 0;
  }

  .list--bullet &:before {
    display: block;
    flex: 0 0 7px;
    width: 7px;
    height: 7px;
    margin-right: gap('xxsmall');
    border-radius: 50%;
    background-color: color('brand-dark');
    content: '';
  }

  .list--bar &:before {
    display: block;
    flex: 0 0 14px;
    width: 14px;
    height: 2px;
    margin-right: gap('xxsmall');
    background-color: color('brand-dark');
    content: '';
  }
}

.list__symbol {
  display: flex;
  flex: 0 0 10px;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  margin-right: gap('xxsmall');
}

.list__label {
  display: block;
  flex: 0 0 45%;
  font-weight: bold;
}

.list__value {
  display: block;
  flex: 1 1 auto;
}
