/**
 *
 * MAIN NAV
 *
 */

.mainNav {
  transition: opacity 500ms, right 300ms 500ms;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  z-index: 90210;
  display: flex;
  align-items: center; justify-content: center;
  width: 100%;
  background-color: color('brand-dark');
  opacity: 0;

  @media (min-width: bp('medium')) {
    position: static;
    opacity: 1;
    background-color: transparent;
  }

  .menu-is-visible & {
    transition: opacity 500ms, right 0ms;
    right: 0;
    opacity: 1;
  }
}

.mainNav__inner {
  @media (min-width: bp('medium')) {
    display: flex;
  }
}

.mainNav__item {
  margin-top: gap('small');
  text-align: center;

  @media (min-width: bp('medium')) {
    margin-top: 0; margin-left: gap('small');
  }

  &:first-child {
    margin-top: 0; margin-left: 0;
  }
}

.mainNav__link {
  transition: color 300ms;
  text-decoration: none;
  color: color('light');
  font-weight: 300;
  display: inline-flex; align-items: center; height: 30px;
  text-align: center;
  font-size: fontSize('large');
  font-family: font('special');
  text-transform: capitalize;
  cursor: pointer;

  @media (min-width: bp('medium')) {
    color: color('neutrals-7');
  }

  @media (min-width: bp('large')) {
    font-size: fontSize('xlarge');
  }

  @media (min-width: bp('xxlarge')) {
    font-size: fontSize('xxlarge');
  }

  &:not(.is-active):hover {
    color: color('hover');
  }

  &.is-active {
    font-weight: 700;
  }
}
