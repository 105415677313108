/**
 *
 * MENU ICON
 *
 */

.menuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $headerMenuIconWidth;
  height: 40px;
  cursor: pointer;
}

.menuIcon__inner {
  transition: transform 300ms, height 150ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 14px;

  .menu-is-visible & {
    transform: rotate(180deg);
    height: $headerMenuIconWidth;
  }
}

.menuIcon__inner:before,
.menuIcon__inner:after {
  transition: transform 300ms;
  display: block;
  width: 100%;
  height: 0;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  content: '';
}

.menuIcon__inner:before {
  .menu-is-visible & {
    transform: translateY(10px) rotate(-45deg);
  }
}

.menuIcon__bar {
  transition: opacity 150ms 150ms;
  display: block;
  width: 100%;
  height: 0;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;

  .menu-is-visible & {
    transition: opacity 150ms;
    opacity: 0;
  }
}

.menuIcon__inner:after {
  .menu-is-visible & {
    transform: translateY(-10px) rotate(45deg);
  }
}
