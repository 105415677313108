/**
 *
 * RICHTEXTCONTENT
 *
 */

.richTextContent {
	color: inherit;

	& > :first-child {
		margin-top: 0;
		padding-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	h1 {
		@extend .heading !optional;
		@extend .heading--1 !optional;

		margin-top: gap('small');
	}

	h2 {
		@extend .heading !optional;
		@extend .heading--2 !optional;

		margin-top: gap('small');
	}

	h3 {
		@extend .heading !optional;
		@extend .heading--3 !optional;

		margin-top: gap('small');
	}

	h4 {
		@extend .heading !optional;
		@extend .heading--4 !optional;

		margin-top: gap('xsmall');
	}

	h5 {
		@extend .heading !optional;
		@extend .heading--5 !optional;

		margin-top: gap('xsmall');
	}

	h6 {
		@extend .heading !optional;
		@extend .heading--6 !optional;

		margin-top: gap('xsmall');
	}

	p {
		margin-top: gap('small');
		margin-bottom: 0;
	}

	strong,
	b {
		font-weight: 900;
	}

	em,
	i {
		font-style: italic;
	}

	a {
		@extend .link--inline !optional;
	}

	blockquote {
		display: flex;
		margin: 0;
		padding: gap('medium') gap('small') gap('small');
		font-family: font('special');
		font-size: titleSize('h3');
		font-weight: normal;

		@media (min-width: bp('large')) {
			padding-right: gap('xlarge');
			font-size: titleSize('xlarge');
		}

		&:before {
			position: relative;
			top: -20px;
			display: block;
			margin-right: gap('xsmall');
			color: color('action');
			font-size: 220%;
			content: '”';
		}
	}

	ul {
		@extend .list !optional;

		li {
			@extend .list__item !optional;
		}
	}

	ul,
	ol {
		margin: 0;
		padding: gap('small') gap('xsmall') gap('xsmall') gap('small');

		@media (min-width: bp('large')) {
			padding: gap('small') gap('small') gap('small') gap('medium');
		}

		li {
			margin-top: gap('xxsmall');

			&:first-child {
				margin-top: 0;
			}
		}
	}

	figure {
		$imgBleed: section('xlarge') - section('medium');

		transform: translateX(-($imgBleed / 2));
		display: block;
		width: calc(100% + #{$imgBleed});
		height: auto;
		margin: gap('small') 0;

		@media (min-width: bp('large')) {
			margin: gap('medium') 0;
		}
	}

	img {
		display: block;
		width: 100%;
	}

	figcaption {
		display: block;
		width: 100%;
		max-width: section('xsmall');
		margin: gap('xsmall') auto 0;
		font-size: fontSize('xsmall');
		text-align: center;
	}

	iframe {
		margin: gap('small') 0;
	}
}
