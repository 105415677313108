/**
 *
 * ACTION NAV
 *
 */

.actionNav {
  display: flex;
}

.actionNav__item {
  display: flex;
  align-items: center;
  height: $headerHeight--small;
  margin-left: gap('xsmall');

  @media (min-width: bp('medium')) {
    height: $headerHeight--medium;
    margin-left: gap('small');
  }

  @media (min-width: bp('large')) {
    height: $headerHeight--large;
  }

  &:first-child {
    margin-left: 0;
  }
}

.actionNav__link {
  transition: color 300ms;
  color: inherit;
  text-decoration: none;
  color: color('brand-dark');
  font-family: font('special');
  font-weight: 400;

  @media (min-width: bp('large')) {
    font-size: fontSize('xlarge');
  }

  @media (min-width: bp('xxlarge')) {
    font-size: fontSize('xxlarge');
  }

  &:hover {
    color: color('hover');
  }
}

.actionNav__symbol {
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @media (min-width: bp('large')) {
    display: none;
  }
}

.actionNav__label {
  display: none;

  @media (min-width: bp('large')) {
    display: block;
  }
}
