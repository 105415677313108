/**
 *
 * VIDEO PLAYER
 *
 */

.videoPlayer {
	@media (min-width: bp('medium')) {
		padding: 0 gap('medium');
	}

	transition: opacity 700ms;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 90210;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0 gap('xxsmall');
	opacity: 0;

	.videoPlayer-is-active & {
		opacity: 1;
	}
}

.videoPlayer__overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, .7);
}

.videoPlayer__inner {
	display: block;
	flex: 1 1 auto;
	width: 100%;
	max-width: section('xxlarge');
}

.videoPlayer__content {
	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	height: 0;
	padding-top: (9 / 16) * 100%;
	background-color: #000;
}

.videoPlayer__action {
	position: absolute;
	right: gap('xxsmall');
	bottom: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	margin-bottom: gap('xxsmall');
	color: color('light');
	font-size: fontSize('small');

	@media (min-width: section('large')) and (max-height: (section('large') / 9)) {
		top: gap('xxsmall');
		bottom: auto;
	}
}

.videoPlayer__action__label {
	display: block;
	flex: 0 0 auto;
	line-height: 1;
}

.videoPlayer__symbol {
	display: block;
	flex: 0 0 14px;
	width: 14px;
	height: 14px;
	margin-left: gap('xxsmall');
}

.videoPlayer__player {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: block;
	width: 100%;
	height: 100%;
}
