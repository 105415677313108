/**
 *
 * COLORS: GENERAL
 *
 */

#{$theBody} {
  color: color('neutrals-6');
  background-color: color('neutrals-0');
}

::selection {
  color: color('light');
  background-color: color('brand-dark');
}

::-moz-selection {
  color: color('light');
  background-color: color('brand-dark');
}
