/**
 *
 * TALENTS
 *
 */

.talents__inner {
  display: block;
  max-width: section('xlarge');
  margin-right: auto;
  margin-left: auto;
}

.talents__content {
  padding: 0 gap('xsmall') gap('small');

  @media (min-width: bp('medium')) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -(gap('xxxsmall'));
  }

  @media (min-width: bp('large')) {
    padding: 0 gap('small') gap('small');
  }

  @media (min-width: bp('xlarge')) {
    padding: 0 0 gap('medium');
  }
}

.talents__item {
  display: flex;
  flex: 0 0 240px;
  margin-top: gap('small');

  @media (min-width: bp('medium')) {
    margin-top: 0;
    flex: 0 0 25%;
    margin-bottom: gap('small');
  }

  &:first-child {
    margin-top: 0;
  }
}

.talents__item__inner {
  display: flex;
  flex: 1 1 auto;

  @media (min-width: bp('medium')) {
    padding: gap('xxxsmall');
  }
}
