/**
 *
 * SYMBOLS
 *
 */

.theSprite {
  position: absolute;
  top: -90210px;
  left: -90210px;
}

svg {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  fill: currentColor;
}

svg {
  g,
  group {
    fill: currentColor;
  }
}
