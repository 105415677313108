/**
 *
 * GALLERY
 *
 */

.gallery {
	padding: 0 gap('xxsmall') gap('small');

	@media (min-width: bp('medium')) {
		padding: 0 gap('xsmall') gap('medium');
	}

	@media (min-width: bp('large')) {
		padding: 0 gap('small') gap('xlarge');
	}
}

.gallery__inner {
	display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 25vw 25vw;
  grid-template-areas:
    "featured featured . ."
    "featured featured . .";
  grid-auto-columns: 1fr;
  grid-auto-rows: 25vw;
  grid-column-gap: gap('xxsmall');
  grid-row-gap: gap('xxsmall');

	@media (min-width: bp('medium')) {
		max-width: section('xlarge');
		margin-right: auto;
		margin-left: auto;
	}
}

.gallery__item {
  background-position: center;
  background-size: cover;

  &:first-child {
    grid-area: featured;
  }
}
