/**
 *
 * FOOTER
 *
 */

.footer {
  padding: gap('xsmall') gap('xsmall') gap('small');
  color: color('light');
  background-color: color('brand-light');

  @media (min-width: bp('medium')) {
    padding: gap('xsmall') gap('xsmall') gap('small');
  }

  @media (min-width: bp('large')) {
    padding: gap('small') gap('small') gap('medium');
  }

  @media (min-width: bp('xxlarge')) {
    padding: gap('medium') gap('medium') gap('large');
  }
}

.footer__inner {
  max-width: section('xxlarge');
  margin-right: auto;
  margin-left: auto;
}

.footer__title {
  font-size: 40px;
  line-height: 1.6;
}

.footer__desc {
  margin-top: gap('xsmall');
  line-height: 1.6;

  @media (min-width: bp('medium')) {
    max-width: section('xxlarge') / 3 * 2;

    column-count: 2;
    column-gap: gap('small');
  }
}

.footer__contact {
  margin-top: gap('small');
  padding-top: gap('small');
  border-top: 1px solid color('light');

  @media (min-width: bp('medium')) {
    margin-top: gap('medium');
  }

  @media (min-width: bp('large')) {
    margin-top: gap('large');
  }
}
