/**
 *
 * BOXING: GENERAL
 *
 */

#{$theBody} {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	&:before {
		position: absolute;
		left: -90210px;
		content: 'xsmall';

		@media (min-width: bp('small')) {
			content: 'small';
		}

		@media (min-width: bp('medium')) {
			content: 'medium';
		}

		@media (min-width: bp('large')) {
			content: 'large';
		}

		@media (min-width: bp('xlarge')) {
			content: 'xlarge';
		}
	}
}

#theBucket {
	// display: block; width: 100%; max-width: section('xxlarge'); margin-left: auto; margin-right: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}
