/**
 *
 * CONTACT
 *
 */

.contact {
  font-family: font('special');
}

.contact__title {
  font-size: fontSize('xxlarge');
  font-weight: 500;
}

.contact__inner {
  @media (min-width: bp('medium')) {
    display: flex;
    justify-content: space-between;
  }
}

.contact__info {
  margin-top: gap('xsmall');
  font-size: fontSize('xlarge');
  font-weight: 500;
  line-height: 1.4;

  @media (min-width: bp('medium')) {
    column-count: 2;
    column-gap: gap('small');
    max-width: section('xxsmall');
  }
}

.contact__item {
  break-inside: avoid;
}

.contact__link {
  color: inherit;
  text-decoration: none;
}

.contact__social {
  margin-top: gap('small');
}
