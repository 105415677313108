/**
 *
 * BUTTONS
 *
 */

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
	margin: 0;
	padding: 0;
	border: 0;
}

.button {
	transition: color 300ms, border-color 300ms, background-color 300ms;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: $buttonHeight--small;
	margin: 0;
	padding: 0 gap('xsmall');
	color: color('light');
	font-family: font('main');
	font-size: fontSize('medium');
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	border: 2px solid color('brand-dark');
	border-radius: 0;
	outline: none;
	background-color: color('brand-dark');
	box-shadow: none;
	cursor: pointer;

	@media (min-width: bp('medium')) {
		height: $buttonHeight--medium;
		font-size: fontSize('large');
	}

	@media (min-width: bp('large')) {
		height: $buttonHeight--large;
		font-size: fontSize('xlarge');
	}

	@media (min-width: bp('xxlarge')) {
		height: $buttonHeight--xlarge;
	}

	// CHILDREN
	& > * {
		margin-left: gap('xsmall');

		&:first-child {
			margin-left: 0;
		}
	}

	.button__label {
		flex: 0 1 auto;
		color: inherit;
	}

	.button__icon {
		transition: transform 300ms;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: center;
		width: fontSize('xsmall');
		height: fontSize('xsmall');
		color: inherit;

		&:empty {
			display: none;
		}
	}
}

// HOVER
.button:hover {
	border-color: color('hover');
	background-color: color('hover');

	.button__icon {
		transform: translateX(2px);
	}
}

// ACTIVE
.button:active {
	transform: translateY(1px);
}

// DISABLED
.button[disabled]:not([disabled='false']),
.button.disabled {
	color: color('neutrals-2');
	border-color: color('neutrals-1');
	background-color: color('neutrals-1');
	box-shadow: none;
	cursor: not-allowed;

	&:active {
		transform: none;
	}

	.button__icon {
		transform: none;
	}
}
