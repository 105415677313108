/**
 *
 * CONFIG
 *
 */

$theBody: 'body';
$theBucket: '#theBucket';

/**
 *
 * ELEMENT BOXING
 *
 */

$headerMenuIconWidth: 22px;

$headerHeight--small: 54px;
$headerHeight--medium: 84px;
$headerHeight--large: 114px;
$headerHeight--xlarge: 130px;

$buNavHeight--small: 40px;
$buNavHeight: 70px;

$buttonHeight--small: 36px;
$buttonHeight--medium: 42px;
$buttonHeight--large: 48px;
$buttonHeight--xlarge: 54px;

/**
 *
 * GENERAL BOXING
 *
 */

$breakpoints: (
  'small'   :  500px,
  'medium'  :  768px,
  'large'   : 1024px,
  'xlarge'  : 1140px,
  'xxlarge' : 1440px
);

$sections: (
  'none'    :   100%,
  'xxxsmall':  200px,
  'xxsmall' :  400px,
  'xsmall'  :  600px,
  'small'   :  768px,
  'medium'  :  960px,
  'large'   : 1024px,
  'xlarge'  : 1140px,
  'xxlarge' : 1440px
);

$gaps: (
  'none'    :   0px,
  'xxxsmall':   4px,
  'xxsmall' :   8px,
  'xsmall'  :  16px,
  'small'   :  32px,
  'medium'  :  48px,
  'large'   :  64px,
  'xlarge'  :  92px,
  'xxlarge' : 128px,
  'xxxlarge': 184px
);

@function section($size: 'xsmall') {
  @return map-get($sections, $size);
}

@function gap($size: 'xsmall') {
  @return map-get($gaps, $size);
}

@function bp($size: 'xsmall') {
  @return map-get($breakpoints, $size);
}

/**
 *
 * COLORS: VARS
 *
 */

$colors: (
  'light': #fff,
  'dark': #000,
  'action': #da0812,
  'hover': #3F7873,
  'disabled': #d9d9d9,
  'positief': #7ED321,
  'negatief': #EC2A2A,
  'brand-dark': #365754,
  'brand-light': #FDBFBC,
  'neutrals-0': #F9F9F9,
  'neutrals-1': #E0E0E0,
  'neutrals-2': #B7B7B7,
  'neutrals-3': #979797,
  'neutrals-4': #777,
  'neutrals-5': #4A4946,
  'neutrals-6': #3B3B3B,
  'neutrals-7': #0A0A0A,
  'neutrals-8': #000
);

@function color($name: false, $mod: false, $amount: 7) {

  @if $mod == lighter {
    @return lighten(map-get($colors, quote($name)), $amount);
  }

  @if $mod == darker {
    @return darken(map-get($colors, quote($name)), $amount);
  }

  @return map-get($colors, quote($name));

}

/**
 *
 * TYPOGRAPHY: VARS
 *
 */

$fonts: (
  'main': (
    'normal'  : 'Libre Baskerville'
  ),
  'special': (
    'normal'  : 'Fira Sans'
  )
);

$fontSizes: (
  'inherit' : inherit,
  'xxsmall' : 10px,
  'xsmall'  : 11px,
  'small'   : 13px,
  'medium'  : 15px,
  'large'   : 17px,
  'xlarge'  : 19px,
  'xxlarge' : 22px,
);

$titleSizes: (
  'inherit' : inherit,
  'h6'      : 12px,
  'h5'      : 14px,
  'h4'      : 16px,
  'h3'      : 18px,
  'h2'      : 26px,
  'h1'      : 46px,
  'h0'      : 60px
);

@function font($face: main, $weight: 'normal') {

  $font: map-get($fonts, quote($face));

  @return map-get($font, quote($weight)), Helvetica, Arial, serif;

}

@function fontSize($size: 'medium') {

  @return map-get($fontSizes, quote($size));

}

@function titleSize($size: 'medium') {

  @return map-get($titleSizes, quote($size));

}