/**
 *
 * BUTTON: DARK
 *
 */

.button--dark {
  color: color('light');
  border-color: color('brand-dark');
  background-color: color('brand-dark');
}

// HOVER
.button--dark:hover {
  border-color: color('brand-dark', 'lighter');
  background-color: color('brand-dark', 'lighter');
}
