/**
 *
 * TALENT
 *
 */

.talent {
  position: relative;
  display: block;
  flex: 1 1 auto;
}

.talent__visual {
  display: block;
  overflow: hidden;
  background-color: color('neutrals-1');
}

.talent__image {
  transition: transform 700ms, opacity 700ms;
  display: block;
  width: 100%;
  height: 0;
  padding-top: (315 / 237) * 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: .9;

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }
}

.talent__content {
  padding: gap('xsmall') gap('xsmall') gap('medium') 0;

  @media (min-width: bp('large')) {
    padding-bottom: gap('large');
  }
}

.talent__desc {
  margin-top: gap('xxsmall');
}

.talent__cta {
  position: absolute;
  bottom: gap('xxsmall');
  left: 0;
  margin-top: gap('xsmall');
}
