/**
 *
 * LINKS: INLINE
 *
 */

.link--inline {
	color: inherit;
	text-decoration: none;
  border-bottom: 2px solid color('brand-light');
}
