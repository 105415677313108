/**
 *
 * INTRO
 *
 */

.intro {
	padding: gap('small') gap('xsmall');

	@media (min-width: bp('medium')) {
		padding: gap('medium') gap('xsmall');
	}

	@media (min-width: bp('large')) {
		padding: gap('medium') gap('small');
	}
}

.intro__inner {
	@media (min-width: bp('medium')) {
		display: block;
		width: 100%;
		max-width: section('xlarge');
		margin-right: auto;
		margin-left: auto;
	}
}

.intro__content {
  @media (min-width: bp('medium')) {
    max-width: section('medium');
  }
}

.intro__title {
	color: color('neutrals-2');
	font-size: 18px;
	font-weight: bold;

	@media (min-width: bp('medium')) {
		font-size: 25px;
	}

	@media (min-width: bp('xxlarge')) {
		font-size: 32px;
	}
}

.intro__desc {
	margin-top: gap('xxsmall');

	@media (min-width: bp('medium')) {
		margin-top: gap('xsmall');
	}
}

.intro__cta {
	margin-top: gap('xsmall');
}
