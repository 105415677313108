/**
 *
 * PLAY BUTTON
 *
 */

.playButton {
	transition: border-width 300ms;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	padding: 0;
	border: 3px solid color('light');
	border-radius: 50%;
	background-color: transparent;

	@media (min-width: bp('medium')) {
		width: 95px;
		height: 95px;
	}

	.playButton__inner {
		transition: transform 300ms;
		transform: translateX(2px);
		display: flex;
		flex: 0 0 52px;
		align-items: center;
		justify-content: center;
		width: 52px;
		height: 52px;
		border-radius: 50%;

		@media (min-width: bp('medium')) {
			flex: 0 0 75px;
			width: 75px;
			height: 75px;
		}
	}

	.playButton__inner:before {
		transform: translateX(1px);
		flex: 0 0 auto;
		width: 0;
		height: 0;
		margin-right: 0;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-left: 20px solid color('light');
		background-color: transparent;
		content: '';

		@media (min-width: bp('medium')) {
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-left: 30px solid color('light');
		}
	}

	.playButton__inner:after {
		content: none;
	}
}

// HOVER
.playButton:hover {
	border: 4px solid color('light');

	.playButton__inner {
		transform: scale(1.15) translateX(2px);
	}
}
