/**
 *
 * HERO
 *
 */

.hero {
  @media (min-width: bp('large')) {
    margin-top: gap('large');
    padding: 0 gap('small');
  }

  @media (min-width: bp('xxlarge')) {
    margin-top: gap('xxlarge');
    padding: 0 gap('medium');
  }
}

.hero__inner {
  @media (min-width: bp('large')) {
    position: relative;
    max-width: section('xxlarge');
    margin-right: auto;
    margin-left: auto;
    padding-right: gap('small');
  }

  @media (min-width: bp('xxlarge')) {
    padding-right: gap('medium');
  }
}

.hero__visual {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: -(gap('xsmall'));
  background-color: color('neutrals-1');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: bp('medium')) {
    margin-bottom: -(gap('medium'));
  }

  @media (min-width: bp('large')) {
    margin-bottom: 0;
  }
}

.hero__video {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}

.hero__in {
  position: relative;
  padding: 0 gap('xxsmall');

  @media (min-width: bp('medium')) {
    padding: 0 gap('xsmall');
  }

  @media (min-width: bp('large')) {
    max-width: 370px;
  }

  @media (min-width: bp('large')) {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 320px;
    margin-top: -(gap('small'));
    padding: 0;
  }

  @media (min-width: bp('xxlarge')) {
    max-width: 430px;
    margin-top: -(gap('medium'));
  }
}

.hero__content {
  display: flex;
  align-items: flex-start;
  padding: gap('xsmall') gap('xsmall') gap('xsmall') 0;
  background-color: color('light');

  @media (min-width: bp('medium')) {
    padding: gap('medium') gap('small');
  }

  @media (min-width: bp('large')) {
    display: block;
    min-height: 400px;
    padding: gap('large') gap('small') 0;
    border: 1px solid color('neutrals-1');
  }

  @media (min-width: bp('xxlarge')) {
    padding: gap('xlarge') gap('medium') 0;
  }
}

.hero__logo {
  position: relative;
  top: -10px;
  display: block;
  flex: 0 0 92px;
  width: 92px;

  @media (min-width: bp('medium')) {
    flex: 0 0 104px;
    width: 104px;
  }

  @media (min-width: bp('large')) {
    flex: 0 0 119px;
    width: 119px;
  }

  @media (min-width: bp('xxlarge')) {
    flex: 0 0 163px;
    width: 163px;
  }
}

.hero__content__inner {
  flex: 1 1 auto;
  margin-left: gap('xsmall');

  @media (min-width: bp('medium')) {
    margin-left: gap('small');
  }

  @media (min-width: bp('large')) {
    margin-top: gap('xxsmall');
    margin-left: 0;
  }

  @media (min-width: bp('xxlarge')) {
    margin-top: gap('xsmall');
  }
}

.hero__title {
  color: color('brand-light');
  font-size: 20px;
  line-height: 1.5;

  @media (min-width: bp('medium')) {
    max-width: 400px;
    font-size: 30px;
  }

  @media (min-width: bp('xlarge')) {
    font-size: 37px;
    line-height: 1.3;
  }

  @media (min-width: bp('xxlarge')) {
    font-size: 41px;
    line-height: 1.6;
  }
}

.hero__desc {
  margin-top: gap('xsmall');

  &:empty {
    display: none;
  }
}

.hero__cta {
  margin-top: gap('xsmall');

  @media (min-width: bp('medium')) {
    font-size: 17px;
  }

  @media (min-width: bp('large')) {
    margin-bottom: gap('medium');
  }

  @media (min-width: bp('xxlarge')) {
    margin-top: gap('xlarge');
  }

  &[href=''] {
    display: none;
  }
}
