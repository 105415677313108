/**
 *
 * HEADER
 *
 */

.header {
  display: block;
  position: relative; z-index: 1;
  height: $headerHeight--small;

  @media (min-width: bp('medium')) {
    height: $headerHeight--medium;
  }

  @media (min-width: bp('large')) {
    height: $headerHeight--large;
  }

  @media (min-width: bp('xxlarge')) {
    padding-top: 20px;
  }
}

.header__inner {
  position: fixed; top: 0; left: 0;
  display: block; width: 100%;
  padding: 0 gap('xxsmall');
  background-color: color('neutrals-0');

  @media (min-width: bp('medium')) {
    padding: 0 gap('xsmall');
  }

  @media (min-width: bp('large')) {
    position: static;
    padding: 0 gap('small');
  }

  @media (min-width: bp('xxlarge')) {
    padding: 0 gap('medium');
  }
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight--small;
  max-width: section('xxlarge'); margin-left: auto; margin-right: auto;
  border-bottom: 1px solid rgba(color('neutrals-3'), .3);

  @media (min-width: bp('medium')) {
    height: $headerHeight--medium;
  }

  @media (min-width: bp('large')) {
    height: $headerHeight--large;
  }
}

.header__trigger {
  z-index: 99999;
  color: color('brand-dark');

  @media (min-width: bp('medium')) {
    display: none;
  }

  .menu-is-visible & {
    color: color('light');
  }
}