/**
 *
 * LINKS
 *
 */

a {
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		color: inherit;
		text-decoration: none;
	}
}

.link {
	transition: color 300ms, opacity 300ms;
	position: relative;
	display: inline-block;
	color: inherit;
	text-decoration: none;
}

.link:after {
	transition: transform 300ms;
  transform: translateY(3px);
	position: absolute;
	top: 100%;
	left: 0;
	display: block;
	width: 100%;
	height: 2px;
	background-color: color('brand-light');
	content: '';
}

.link:hover {
	cursor: pointer;
	color: color('neutrals-8');
}

.link:hover:after {
	transform: translateY(0);
}
