/**
 *
 * BUTTONS: SMALL
 *
 */

.button--small {
  height: $buttonHeight--small;
  font-size: fontSize('medium');

  .button__icon {
    width: fontSize('xxsmall');
    height: fontSize('xxsmall');
  }
}
