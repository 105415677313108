/**
 *
 * MAIN CONTENT
 *
 */

.mainContent {
  padding: gap('small') gap('xsmall');

  @media (min-width: bp('medium')) {
    padding: gap('medium') gap('xsmall');
  }

  @media (min-width: bp('large')) {
    padding: gap('medium') gap('small');
  }
}

.mainContent__inner {
  @media (min-width: bp('medium')) {
    max-width: section('xlarge'); margin-left: auto; margin-right: auto;
  }
}

.mainContent__title {
  color: color('neutrals-2');
  font-weight: bold;
  font-size: 18px;

  @media (min-width: bp('medium')) {
    font-size: 25px;
  }

  @media (min-width: bp('xxlarge')) {
    font-size: 32px;
  }
}

.mainContent__desc {
  margin-top: gap('xxsmall');
  line-height: 1.66;

  @media (min-width: bp('medium')) {
    margin-top: gap('xsmall');
    column-count: 2;
    column-gap: gap('small');
  }
}

.mainContent__cta {
  margin-top: gap('xsmall');
}