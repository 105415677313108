/**
 *
 * HEADINGS
 *
 */

.heading {
	display: block;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 400;
	line-height: 1.2;

	&.inverted {
		color: color('light');
	}
}

.heading--0 {
	font-size: titleSize('h0') / 1.5;

	@media (min-width: bp('medium')) {
		font-size: titleSize('h0') / 1.2;
	}

	@media (min-width: bp('xlarge')) {
		font-size: titleSize('h0');
	}
}

.heading--1 {
	font-size: titleSize('h1') / 1.7;

	@media (min-width: bp('medium')) {
		font-size: titleSize('h1');
	}
}

.heading--2 {
	font-size: titleSize('h2') / 1.3;

	@media (min-width: bp('medium')) {
		font-size: titleSize('h2');
	}
}

.heading--3 {
	font-size: titleSize('h3') / 1.1;

	@media (min-width: bp('medium')) {
		font-size: titleSize('h3');
	}
}

.heading--4 {
	font-size: titleSize('h4');

	@media (min-width: bp('medium')) {
		font-size: titleSize('h4');
	}
}

.heading--5 {
	font-size: titleSize('h5');
}

.heading--6 {
	font-size: titleSize('h6');
}
