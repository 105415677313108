/**
 *
 * BUTTONS: FLUID
 *
 */

.button--fluid {
  display: flex;
  width: 100%;
}
