/**
 *
 * TYPOGRAPHY: GENERAL
 *
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

#theBucket {
  font-family: font('main');
  font-size: fontSize('medium');
  font-weight: normal;
  line-height: 1.6;
}

.screenReader {
  position: absolute;
  left: -90210px;
}
