/**
 *
 * BOXING: INLINES
 *
 */

@each $gapName, $gapValue, $gap in $gaps {
  .gap--top-in-#{$gapName} {
    padding-top: #{$gapValue / 1.2};

    @media (min-width: bp('medium')) {
      padding-top: #{$gapValue};
    }
  }

  .gap--bottom-in-#{$gapName} {
    padding-bottom: #{$gapValue / 1.2};

    @media (min-width: bp('medium')) {
      padding-bottom: #{$gapValue};
    }
  }

  .gap--top-out-#{$gapName} {
    margin-top: #{$gapValue / 1.2};

    @media (min-width: bp('medium')) {
      margin-top: #{$gapValue};
    }
  }

  .gap--bottom-out-#{$gapName} {
    margin-bottom: #{$gapValue / 1.2};

    @media (min-width: bp('medium')) {
      margin-bottom: #{$gapValue};
    }
  }
}