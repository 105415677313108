/**
 *
 * SOCIAL NAV
 *
 */

.socialNav {
  display: flex;
  align-items: center;
}

.socialNav__link {
  display: flex;
  flex: 0 0 35px;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-left: gap('xsmall');
  color: inherit;
  text-decoration: none;

  @media (min-width: bp('large')) {
    margin-left: gap('xxsmall');
  }

  &:first-child {
    margin-left: 0;
  }
}
