/**
 *
 * BUTTONS: GHOST
 *
 */

.button--ghost {
  color: color('light');
  border-color: color('light');
  background-color: transparent;
}

// HOVER
.button--ghost:hover {
  color: color('light');
  border-color: color('brand-dark');
  background-color: color('brand-dark');
}

// DARK
.button--ghost.button--dark {
  color: color('brand-dark');
  border-color: color('brand-dark');
}

// DARK HOVER
.button--ghost.button--dark:hover {
  color: color('light');
  border-color: color('brand-dark', 'lighter');
  background-color: color('brand-dark', 'lighter');
}
